<template>
  <div>
    <p>
      {{ dialogData.Description }}
    </p>
  </div>
</template>

<script>
export default {
  name: "DialogContinueTo",
  props: {
    dialogData: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    actions() {
      return this.dialogData?.GlobalActions;
    },
  },
};
</script>
